import { TimeAuditedModel } from "@/ORM/Global/TimeAuditedModel.js";

import { Hackathon } from "@/ORM/Hackathon/Hackathon.js";
import { Profile } from "@/ORM/Hackathon/Profile.js";

class Mentor extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.profile = data.profile ? new Profile(data.profile) : new Profile();
        this.hackathon = data.hackathon
            ? new Hackathon(data.hackathon)
            : new Hackathon();
        this.status = data.status || "DISABLED";
    }
}

class MentorInvite extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.uid = data.uid || null;
        this.hackathon = data.hackathon
            ? new Hackathon(data.hackathon)
            : new Hackathon();
        this.email = data.email || "";
        this.status = data.status || "pending";
        this.responded_profile = data.respondedProfile
            ? new Profile(data.respondedProfile)
            : new Profile();
    }
}

class Volunteer extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.profile = data.profile ? new Profile(data.profile) : new Profile();
        this.hackathon = data.hackathon
            ? new Hackathon(data.hackathon)
            : new Hackathon();
        this.status = data.status || "DISABLED";
    }
}

class VolunteerInvite extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.uid = data.uid || null;
        this.hackathon = data.hackathon
            ? new Hackathon(data.hackathon)
            : new Hackathon();        this.email = data.email || "";
        this.status = data.status || "pending";
        this.responded_profile = data.respondedProfile
            ? new Profile(data.respondedProfile)
            : new Profile();
    }
}

const modelMapping = {
    hackathonEventTeamMentor: Mentor,
    allHackathonEventTeamMentor: Mentor,
    hackathonEventTeamMentorInvite: MentorInvite,
    allHackathonEventTeamMentorInvite: MentorInvite,
    hackathonEventTeamVolunteer: Volunteer,
    allHackathonEventTeamVolunteer: Volunteer,
    hackathonEventTeamVolunteerInvite: VolunteerInvite,
    allHackathonEventTeamVolunteerInvite: VolunteerInvite,
};

function processHackathonEventTeamRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export {
    Mentor,
    MentorInvite,
    Volunteer,
    VolunteerInvite,
    processHackathonEventTeamRelayData,
};
