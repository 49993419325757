import { User } from "@/ORM/Global/User.js";
import { TimeAuditedModel } from "@/ORM/Global/TimeAuditedModel.js";
import { Hackathon } from "@/ORM/Hackathon/Hackathon.js";

class Skill extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.name = data.name || "";
        this.is_default = data.isDefault || false;
    }
}

class Profile extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.user = data.user ? new User(data.user) : new User();
        this.linkedin_url = data.linkedinUrl || null;
        this.github_url = data.githubUrl || null;
        this.skills = Array.isArray(data.skills)
            ? data.skills.map((skill) => new Skill(skill))
            : [];
        this.bio = data.bio || null;
    }
}

const modelMapping = {
    skill: Skill,
    allSkill: Skill,
    hackathonProfile: Profile,
    allHackathonProfile: Profile,
};

function processHackathonProfileRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { Skill, Profile, processHackathonProfileRelayData };
